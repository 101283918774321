export const getUtilityData = async (id) => {
    try {
        /*const apiKey = '497ea80a7f5149c3a6b2348ae956cd40acd5000bd427410c9a7104f81833166ca74266d26bbe4d5c8dbfccd347ddf8d1';
        const url = 'https://report.smartsiteportal.com/api/v1/';
        const endpoint = 'Building/Utility/86';
        const headers = {
            'API-Token': apiKey
        }
        const result = await fetch(`${url}${endpoint}`, {
            headers,
            method: 'GET'
        });
        const data = await result.json();
        const utilityData = {
            totalSavings: 56000
        }*/
        const utilityData = {
            "totalSavings": 56347,
            "Water": {
              "name": "Water",
              "units": "m3",
              "actual": [
                {
                  "value": 18289.99,
                  "label": "February"
                },
                {
                  "value": 21219.49,
                  "label": "March"
                },
                {
                  "value": 20106.21,
                  "label": "April"
                },
                {
                  "value": 20369.08,
                  "label": "May"
                },
                {
                  "value": 24337.46,
                  "label": "June"
                },
                {
                  "value": 23927.39,
                  "label": "July"
                },
                {
                  "value": 23672.66,
                  "label": "August"
                },
                {
                    "value": 22433.23,
                    "label": "September"
                },
                {
                    "value": 21343.23,
                    "label": "October"
                },
                {
                    "value": 20212.34,
                    "label": "November"
                },
                {
                    "value": 19231.32,
                    "label": "December"
                },
                {
                    "value": 20251.05,
                    "label": "January"
                },
              ],
            },
            "Electricity": {
              "name": "Electricity",
              "units": "kWh",
              "actual": [
                {
                  "value": 13378.06,
                  "label": "February"
                },
                {
                  "value": 13171.1,
                  "label": "March"
                },
                {
                  "value": 12252.36,
                  "label": "April"
                },
                {
                  "value": 13927.56,
                  "label": "May"
                },
                {
                  "value": 16937.97,
                  "label": "June"
                },
                {
                  "value": 17657.51,
                  "label": "July"
                },
                {
                  "value": 18895.33,
                  "label": "August"
                },
                {
                  "value": 16999.72,
                  "label": "September"
                },
                {
                  "value": 14717.76,
                  "label": "October"
                },
                {
                    "value": 15332.02,
                    "label": "November"
                },
                {
                    "value": 13321.33,
                    "label": "December"
                },
                {
                    "value": 14301.43,
                    "label": "January"
                },
              ],
            },
            "Natural_Gas": {
              "name": "Natural_Gas",
              "units": "m3",
              "actual": [
                {
                  "value": 19900.21,
                  "label": "February"
                },
                {
                  "value": 17144.42,
                  "label": "March"
                },
                {
                  "value": 12560.25,
                  "label": "April"
                },
                {
                  "value": 9696.2,
                  "label": "May"
                },
                {
                  "value": 4971.34,
                  "label": "June"
                },
                {
                  "value": 4687.58,
                  "label": "July"
                },
                {
                  "value": 5157.19,
                  "label": "August"
                },
                {
                  "value": 5060.42,
                  "label": "September"
                },
                {
                  "value": 10912.81,
                  "label": "October"
                },
                {
                    "value": 12237.23,
                    "label": "November"
                },
                {
                    "value": 14572.27,
                    "label": "December"
                },
                {
                    "value": 16516.21,
                    "label": "January"
                },
              ],
            }
          };
        const numOfMonth = 12;
        const savingsWater = 24423;
        const savingsGas = 18957;
        const savingsElec = 12496;
        utilityData['Water']['savings'] = [];
        utilityData['Water']['accSavings'] = [];
        utilityData['Water']['forecasted'] = [];
        utilityData['Natural_Gas']['savings'] = [];
        utilityData['Natural_Gas']['accSavings'] = [];
        utilityData['Natural_Gas']['forecasted'] = [];
        utilityData['Electricity']['savings'] = [];
        utilityData['Electricity']['accSavings'] = [];
        utilityData['Electricity']['forecasted'] = [];


        let accSavingsWater = 0;
        let accSavingsGas = 0;
        let accSavingsElec = 0;
        for (let i = 0; i < 12; i++) {
            let sWater = (savingsWater / 12) + getRndInteger(-1000, 1000);
            let sGas = (savingsGas / 12) + getRndInteger(-1000, 1000);
            let sElec = (savingsElec / 12) + getRndInteger(-1000, 1000);
            
            if (i === 11) {
                sWater = savingsWater - accSavingsWater;
                sGas = savingsGas - accSavingsGas;
                sElec = savingsElec - accSavingsElec;
            }

            accSavingsWater += sWater;
            accSavingsGas += sGas;
            accSavingsElec += sElec;

            const label = utilityData['Water']['actual'][i]['label']

            utilityData['Water']['savings'][i] = {label, value: sWater};
            utilityData['Natural_Gas']['savings'][i] = {label, value: sGas};
            utilityData['Electricity']['savings'][i] = {label, value: sElec};

            utilityData['Water']['accSavings'][i] = {label, value: accSavingsWater};
            utilityData['Natural_Gas']['accSavings'][i] = {label, value: accSavingsGas};
            utilityData['Electricity']['accSavings'][i] = {label, value: accSavingsElec};

            utilityData['Water']['forecasted'][i] = {label, value: utilityData['Water']['actual'][i]['value'] + sWater};
            utilityData['Natural_Gas']['forecasted'][i] = {label, value: utilityData['Natural_Gas']['actual'][i]['value'] + sGas};
            utilityData['Electricity']['forecasted'][i] = {label, value: utilityData['Electricity']['actual'][i]['value'] + sElec};
        }

        function getRndInteger(min, max) {
            return Math.floor(Math.random() * (max - min) ) + Math.floor(min);
        }
        console.log(utilityData);
        return utilityData
    } catch (e) {
        console.error(e);
        return {}
    }
}

export const getPoints = async (id, point, currentValue) => {
    try {
        const url = 'https://api.smartsiteportal.com'
        const apiKey = 'UOCCFoxh05UdiFbov83b6LKu2H1sIamb';
        const headers = {
            'API-Token': apiKey,
        };
        const endpoint = `${url}/v1/point/${id}${currentValue ? '/CurrentValue' : ''}`;
        const result = await fetch(endpoint, {
            headers,
            method: 'GET'
        });
        console.log(result)
        return {point, value: await result.json()};
    } catch (e) { 
        console.error(e)
        return null;
    }
}