import BuildingSearch from '../../../components/Search/BuildingSearch';
import React from 'react';

const BuildingView = () => {
  return (
    <div className='viewWrap' data-testid='buildingViewWrap'>
      <div className='viewHeader' data-testid='buildingViewHeader'>
        <h3>BUILDING VIEW</h3>
      </div>
      <BuildingSearch className='search' data-testid='buildingSearch' />
    </div>
  );
};

export default BuildingView;
