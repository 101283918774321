import React, { useEffect, useState } from 'react';
import { Box, Tabs, Tab, Typography, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {exportXls } from '../../../api/excel';
import Chart from 'chart.js/auto';

const useStyles = makeStyles((theme) => ({
  subtitle: {
    marginTop: '16px !important',
    fontWeight: 'bold !important',
    textTransform: 'uppercase !important',
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'right'
  }
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const generateSavingsColors = (savings) => {
  const red = '#fc0213';
  const green = '#5CCC8A';
  return savings.map(s => true ? green : red);
}

const drawGas = (gasUtil, id) => {
  const gasCtx = document.getElementById(id).getContext('2d');
  const gasChart = new Chart(gasCtx, {
    type: 'bar',
    data: {
      labels: gasUtil['actual'].map(point => point.label),
      datasets: [
        {
          label: 'Forecasted consumption',
          data: gasUtil['forecasted'].map(point => point.value),
          backgroundColor: '#d66264',
        },
        {
          label: 'Actual consumption',
          data: gasUtil['actual'].map(point => point.value),
          backgroundColor: '#304659',
        },
        {
          label: 'Savings',
          data: gasUtil['accSavings'].map(point => point.value),
          backgroundColor: generateSavingsColors(gasUtil['accSavings']),
          borderColor: '#69fc53',
        },
      ],
    },
    options: {
      scales: {
        y: {
          ticks: {
            callback: (value, index, values) => `$ ${value}`
          }
        }
      }
    }
  });
  return gasChart;
};

const drawWater = (waterUtil, id) => {
  const waterCtx = document.getElementById(id).getContext('2d');
  const waterChart = new Chart(waterCtx, {
    type: 'bar',
    data: {
      labels: waterUtil['actual'].map(point => point.label),
      datasets: [
        {
          label: 'Forecasted consumption',
          data: waterUtil['forecasted'].map(point => point.value),
          backgroundColor: '#d66264',
        },
        {
          label: 'Actual consumption',
          data: waterUtil['actual'].map(point => point.value),
          backgroundColor: '#304659',
        },
        {
          label: 'Savings',
          data: waterUtil['accSavings'].map(point => point.value),
          backgroundColor: generateSavingsColors(waterUtil['accSavings']),
          borderColor: '#69fc53'
        },
      ],
    },
    options: {
      scales: {
        y: {
          ticks: {
            callback: (value, index, values) => `$ ${value}`
          }
        }
      }
    }
  });
  return waterChart;
};

const drawElec = (elecUtil, id) => {
  const elecCtx = document.getElementById(id).getContext('2d');
  const elecChart = new Chart(elecCtx, {
    type: 'bar',
    data: {
      labels: elecUtil['actual'].map(point => point.label),
      datasets: [
        {
          label: 'Forecasted consumption',
          data: elecUtil['forecasted'].map(point => point.value),
          backgroundColor: '#d66264',
        },
        {
          label: 'Actual consumption',
          data: elecUtil['actual'].map(point => point.value),
          backgroundColor: '#304659',
        },
        {
          label: 'Savings',
          data: elecUtil['accSavings'].map(point => point.value),
          backgroundColor: generateSavingsColors(elecUtil['savings']),
          borderColor: '#69fc53'
        },
      ],
    },
    options: {
      scales: {
        y: {
          ticks: {
            callback: (value, index, values) => `$ ${value}`
          }
        }
      }
    }
  });
  return elecChart;
};

const GasWaterElecInfo = ({ utilValues }) => {
  const classes = useStyles();
  const [gasCanvasId, setGasCanvasId] = useState('gas-canvas');
  const [waterCanvasId, setWaterCanvasId] = useState('water-canvas');
  const [elecCanvasId, setElecCanvasId] = useState('elec-canvas');
  const [currentTab, setCurrentTab] = useState(0);
  useEffect(() => {
    let chart;
    if (currentTab === 0) {
      try {
        chart = drawWater(utilValues['Water'], waterCanvasId);
      } catch (e) {
        setWaterCanvasId(`water-canvas-${Math.round(Math.random() * 10000)}`)
      }
    } else if (currentTab === 1) {
      try {
        chart = drawGas(utilValues['Natural_Gas'], gasCanvasId);
      } catch (e) {
        setGasCanvasId(`gas-canvas-${Math.round(Math.random() * 10000)}`)
      }
    } else {
      try {
        chart = drawElec(utilValues['Electricity'], elecCanvasId);
      } catch (e) {
        setElecCanvasId(`elec-canvas-${Math.round(Math.random() * 10000)}`)
      }
    }
    return () => chart && chart.destroy();
  }, [currentTab, utilValues, waterCanvasId, gasCanvasId, elecCanvasId]);

  const _export = () => {
    exportXls(utilValues, 'export')
  };
  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant='h6' className={classes.subtitle}>
        Current Year Utility Statistics 
        <div className={classes.buttonContainer}>
          <Button variant='contained' style={{marginBottom: '8px', minHeight: '40px'}}>Live consumption data</Button>
          <Button variant='contained' onClick={_export} >Export</Button>
        </div>
      </Typography>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={currentTab} onChange={(e, newValue) => setCurrentTab(newValue)} variant='fullWidth'>
          <Tab label='WATER' />
          <Tab label='GAS' />
          <Tab label='ELECTRICITY' />
        </Tabs>
      </Box>
      <TabPanel value={currentTab} index={0}>
        <canvas id={waterCanvasId} />
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        <canvas id={gasCanvasId} />
      </TabPanel>
      <TabPanel value={currentTab} index={2}>
        <canvas id={elecCanvasId} />
      </TabPanel>
    </Box>
  );
};

export default GasWaterElecInfo;
