import React from 'react';
import './styles.css';

const FormInput = ({ handleChange, label, ...otherProps }) => {
  return (
    <div className='formRow' data-testid='formInput'>
      {label && <label>{label}</label>}
      <input className='formInput' onChange={handleChange} {...otherProps} />
    </div>
  );
};

export default FormInput;
