import React, { useState } from 'react';
import './styles.css';
import AddressCard from 'cards/AddressCard';

const BuildingCard = ({ building }) => {
  const [showBody, toggleShowBody] = useState(false);
  return (
    <div className='buildingWrap' data-testid='buildingWrap'>
      <div
        className='buildingHeader'
        data-testid='buildingHeader'
        onClick={() => {
          toggleShowBody(!showBody);
        }}
      >
        <div className='buildingName' data-testid='buildingName'>
          <h3>{building.name}</h3>
        </div>
        <div className='buildingId' data-testid='buildingId'>
          <p>Building ID: {building.id}</p>
        </div>
      </div>
      {showBody && (
        <div className='buildingBody' data-test-id='buildingBody'>
          <div className='clientId' data-testid='clientId'>
            <p>Client ID: {building.client.id}</p>
          </div>
          <div className='buildingAddress' data-testid='buildingAddress'>
            <AddressCard address={building.address} />
          </div>
        </div>
      )}
    </div>
  );
};

export default BuildingCard;
