import React from 'react';
import { Typography, Divider, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
    color: 'rgb(48, 70, 89)'
  },
  subtitle: {
    fontWeight: 'bold !important',
    marginTop: '24px !important',
  },
  secDivider: {
    marginTop: '24px !important',
    marginBottom: '16px !important',
  },
  serviceButton: {
    marginTop: '16px !important',
  },
  colWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  col: {
    marginLeft: 8,
    marginRight: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  divider: {
    height: '100%',
  },
}));

const Info = ({ building }) => {
  const classes = useStyles();
  const {contacts} = building;
  return (
    <div className={classes.wrapper}>
      <Typography variant='h6' className={classes.subtitle}>
        OPERATOR
      </Typography>
      <Typography variant='body1' className={classes.opName}>
        {contacts.firstContactName}
      </Typography>
      <Typography variant='body1' className={classes.opPhone}>
        {contacts.firstContactInfo}
      </Typography>

      <Divider className={classes.secDivider} />

      <Typography variant='h6' className={classes.subtitle}>
        HVAC CONTRACTOR
      </Typography>
      <Typography variant='body1' className={classes.conName}>
        {contacts.secondContactName}
      </Typography>
      <Typography variant='body1' className={classes.conPhone}>
        {contacts.secondContactInfo}
      </Typography>

      <a href="#" target='_blank'>
        <Button variant='contained' className={classes.serviceButton}>
          REQUEST SERVICE
        </Button>
      </a>

      <Divider className={classes.secDivider} />

      <Typography variant='h6' className={classes.subtitle}>
        SAVINGS
      </Typography>
      <div className={classes.colWrapper}>
        <div className={classes.col}>
          <Typography variant='overline'>Lifetime</Typography>
          <Typography variant='h5'>$91,925</Typography>
        </div>
        <Divider orientation='vertical' className={classes.divider} />
        <div className={classes.col}>
          <Typography variant='overline'>This year</Typography>
          <Typography variant='h5'>{building.totalSavingsYTDF}</Typography>
        </div>
      </div>

      <Divider className={classes.secDivider} />

      <Typography variant='h6' className={classes.subtitle}>
        ALERTS
      </Typography>
      <div className={classes.colWrapper}>
        <div className={classes.col}>
          <Typography variant='overline'>Outstanding</Typography>
          <Typography variant='h5'>{building.alertCount}</Typography>
        </div>
        <Divider orientation='vertical' className={classes.divider} />
        <div className={classes.col}>
          <Typography variant='overline'>In progress</Typography>
          <Typography variant='h5'>0</Typography>
        </div>
      </div>

      <Divider className={classes.secDivider} />

      <Typography variant='h6' className={classes.subtitle}>
        USEFUL LINKS
      </Typography>
      <a className={classes.link} href='https://smartsiteportal.com/'><Typography variant='overline'>SmartSite &gt;</Typography></a>
      <br />
      <a className={classes.link} href='mailto:support@kontrolenergy.com'><Typography variant='overline'>Kontrol Energy Support &gt;</Typography></a>
    </div>
  );
};

export default Info;
