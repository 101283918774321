import React from 'react';
import { Drawer, IconButton, Typography, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CurrentActivity from './CurrentActivity';
import Totals from './Totals';

const drawerWidth = 440;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  divider: {
    marginTop: '2rem !important'
  },
  drawerPaper: {
    width: drawerWidth,
    padding: theme.spacing(2),
    display: 'flex',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: theme.spacing(0, 1),
  },
  title: {
    textTransform: 'uppercase',
    marginTop: theme.spacing(2),
  },
  subtitle: {
    marginTop: theme.spacing(2),
  },
  currentActivity: {
    marginTop: theme.spacing(6),
  },
  totals: {
    marginTop: theme.spacing(8),
  },
  copyright: {
    marginTop: `${theme.spacing(6)} !important`,
  },
}));

const SmartSiteDrawer = ({ open, handleDrawerClose, totalSavings, totalSavingsWeekly, totalSavingsMonthly, totalSavingsDaily }) => {
  const classes = useStyles();
  return (
    <Drawer
      className={classes.drawer}
      variant='persistent'
      anchor='left'
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
      <Typography variant='h4' className={classes.title}>
        Welcome back to your smartsite dashboard
      </Typography>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider className={classes.divider} />
      <Typography variant='body1' className={classes.subtitle}>
      </Typography>
      <div className={classes.currentActivity}>
        <CurrentActivity />
      </div>
      <div className={classes.totals}>
        <Totals totalSavings={totalSavings} totalSavingsMonthly={totalSavingsMonthly} totalSavingsWeekly={totalSavingsWeekly} totalSavingsDaily={totalSavingsDaily} />
      </div>
      <Typography variant='overline' className={classes.copyright}>
        Kontrol Technologies, all rights reserved.
      </Typography>
    </Drawer>
  );
};

export default SmartSiteDrawer;
