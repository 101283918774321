import keyStoreTypes from './keyStore.types';

const INIT_STATE = {
  loading: false,
  keyStores: [],
  error: '',
};

const keyStoreReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case keyStoreTypes.GET_ALL_KEYSTORES_SUCCESS:
      return {
        loading: false,
        keyStores: action.payload,
        error: '',
      };
    case keyStoreTypes.GET_ALL_KEYSTORES_FAILURE:
      return {
        loading: false,
        keyStores: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default keyStoreReducer;
