import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllBuildingsFailure, getAllBuildingsSuccess } from '../../store/Building/building.actions';
import { useQuery } from '@apollo/client';
import Table from 'components/Table';
import * as Constants from '../../common/constants';

const headers = ['ID', 'Name', 'Client ID'];

const BuildingTable = () => {
  const buildings = useSelector((state) => state.building.buildings);
  const dispatch = useDispatch();
  const { loading, error, data } = useQuery(Constants.GET_ALL_BUILDINGS);

  if (loading) return 'Loading...';
  if (error) dispatch(getAllBuildingsFailure(error.message));
  else {
    dispatch(getAllBuildingsSuccess(data.getAllBuildings));
  }

  return (
    <div className='tableWrap' data-testid='buildingTable'>
      <Table className='buildingTable' label='CURRENT BUILDINGS' headers={headers} data={buildings}></Table>
    </div>
  );
};

export default BuildingTable;
