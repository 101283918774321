import clientTypes from './client.types';

const INIT_STATE = {
  loading: false,
  clients: [],
  error: '',
};

const clientReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case clientTypes.GET_ALL_CLIENTS_SUCCESS:
      return {
        loading: false,
        clients: action.payload,
        error: '',
      };
    case clientTypes.GET_ALL_CLIENTS_FAILURE:
      return {
        loading: false,
        clients: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default clientReducer;
