import ClientSearch from '../../../components/Search/ClientSearch';
import React from 'react';
import './styles.css';

const ClientView = () => {
  return (
    <div className='viewWrap' data-testid='clientViewWrap'>
      <div className='viewHeader' data-testid='clientViewHeader'>
        <h3>CLIENT VIEW</h3>
      </div>
      <div className='searchWrap' data-testid='clientSearchWrap'>
        <ClientSearch className='search' data-testId='clientSearch' />
      </div>
    </div>
  );
};

export default ClientView;
