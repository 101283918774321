import React, { useState } from 'react';
import './styles.css';
import Table from 'components/Table';
import CreateKeyStore from 'components/CreateKeyStore';
import CreateBuilding from 'components/CreateBuilding';
// import BuildingCard from 'cards/BuildingCard';
import Button from 'components/Forms/Button';

// const mapBuildingsToCards = () => {
//   if (client.buildings.length > 0) {
//     return client.buildings.map((building) => {
//       return (
//         <div className='buildingCard'>
//           <BuildingCard building={building} />
//         </div>
//       );
//     });
//   }
// };

const keyStoreHeaders = ['KEY', 'TYPE'];
const buildingHeaders = ['ID', 'NAME', 'CLIENT ID'];

const ClientCard = ({ client }) => {
  const [showCreateKeyStore, toggleShowCreateKeyStore] = useState(false);
  const [showCreateBuilding, toggleShowCreateBuilding] = useState(false);
  const [showBody, toggleShowBody] = useState(false);
  return (
    <div className='clientWrap' data-testid='clientWrap'>
      <div
        className='clientHeader'
        data-testid='clientHeader'
        onClick={() => {
          toggleShowBody(!showBody);
        }}
      >
        <div className='clientName' data-testid='clientName'>
          <h3>{client.name}</h3>
        </div>
        <div className='clientId' data-testid='clientId'>
          <p>Client ID: {client.id}</p>
        </div>
      </div>
      {showBody && (
        <div className='clientBody' data-testid='clientBody'>
          <div className='clientKeyStores' data-testid='clientKeyStores'>
            <Table headers={keyStoreHeaders} data={client.keyStores} label='API Keys'></Table>
          </div>
          <div className='clientBuildings' data-testid='clientBuildings'>
            <Table headers={buildingHeaders} data={client.buildings} label='Buildings'></Table>
          </div>
          <div className='clientButtons' data-testid='clientButtons'>
            <Button
              className='toggleButton formButton'
              onClick={() => {
                toggleShowCreateKeyStore(!showCreateKeyStore);
                toggleShowCreateBuilding(false);
              }}
            >
              Add API Key to Client
            </Button>
            <Button
              className='toggleButton formButton'
              onClick={() => {
                toggleShowCreateBuilding(!showCreateBuilding);
                toggleShowCreateKeyStore(false);
              }}
            >
              Add Bulding to Client
            </Button>
          </div>
          <div className='creationWrap' data-testid='creationWrap'>
            {showCreateKeyStore && <CreateKeyStore clientId={client.id} data-testid='createKeyStore' />}
            {showCreateBuilding && <CreateBuilding clientId={client.id} data-testid='createBuilding' />}
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientCard;
