import React, { useState } from 'react';
import FormInput from 'components/Forms/FormInput';
import Button from 'components/Forms/Button';
import { useLazyQuery } from '@apollo/client';
import * as Constants from 'common/constants';
import BuildingCard from 'cards/BuildingCard';
import './styles.css';

const BuildingSearch = () => {
  const [buildingId, setBuildingId] = useState('');
  const [getBuildingById, { loading, data, error }] = useLazyQuery(Constants.GET_BUILDING_BY_ID, {
    fetchPolicy: 'network-only',
  });

  return (
    <div className='searchWrap' data-testid='buildingSearchWrap'>
      <div className='searchForm' data-testid='buildingSearchForm'>
        <FormInput
          className='formInput'
          data-testid='buildingSearchFormInput'
          name='buildingId'
          value={buildingId}
          placeholder='Building ID'
          onChange={(e) => setBuildingId(e.target.value)}
        />
        <Button
          className='formButton'
          data-testid='buildingSearchFormButton'
          onClick={() => getBuildingById({ variables: { buildingId: buildingId } })}
        >
          Search
        </Button>
      </div>
      <div className='searchResult' data-testid='buildingSearchResult'>
        {loading && (
          <p className='searchLoading' data-testid='clientSearchLoading'>
            Loading...
          </p>
        )}
        {error && (
          <p className='searchError' data-testid='buildingSearchError'>
            Error!
          </p>
        )}
        {data && data.getBuildingById && (
          <BuildingCard className='card' data-testid='buildingCard' building={data.getBuildingById} />
        )}
      </div>
    </div>
  );
};

export default BuildingSearch;
