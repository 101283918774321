import clientTypes from './client.types';

export const getAllClientsSuccess = (clients) => ({
  type: clientTypes.GET_ALL_CLIENTS_SUCCESS,
  payload: clients,
});

export const getAllClientsFailure = (error) => ({
  type: clientTypes.GET_ALL_CLIENTS_FAILURE,
  payload: error,
});
