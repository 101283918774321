import React from 'react';
import './styles.css';

const AddressCard = ({ address }) => {
  return (
    <div className='addressWrap' data-testid='addressWrap'>
      <div className='addressMain' data-testid='addressMain'>
        <p>
          {address.streetAddress}, {address.city}, {address.state}
        </p>
      </div>
      <div className='addressCode' data-testid='addressCode'>
        <p>{address.code}</p>
      </div>
      <div className='addressCountry' data-testid='addressCountry'>
        <p>{address.country}</p>
      </div>
    </div>
  );
};

export default AddressCard;
