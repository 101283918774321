import AdminDashboard from 'components/View/AdminDashboard';
import ClientView from 'components/View/ClientView';
import BuildingView from 'components/View/BuildingView';
import React from 'react';
import './styles.css';

const AdminHomePage = () => {
  return (
    <div className='adminHomePageWrap' data-testid='adminHomePageWrap'>
      <div className='section dashboard' data-testid='adminHomePageComponent'>
        <AdminDashboard />
      </div>
      <div className='section clientView' data-testid='adminHomePageComponent'>
        <ClientView />
      </div>
      <div className='section buildingView' data-testid='adminHomePageComponent'>
        <BuildingView />
      </div>
    </div>
  );
};

export default AdminHomePage;
