import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export const exportXls = (data, filename) => {
    const sheets = {}
    const sheetNames = []
    const filteredKeys = ['totalSavings']
    for (const [key, value] of Object.entries(data).filter(([key, value]) => !filteredKeys.includes(key))) {
        const sheetData = []
        for (let i = 0; i < value['actual'].length; i++) {
            sheetData.push({
                'Month': value['actual'][i]['label'],
                'Actual': value['actual'][i]['value'],
                'Forecasted': value['forecasted'][i]['value'],
                'Savings': value['savings'][i]['value'],
                'Accum Savings': value['accSavings'][i]['value']
            })
        }
        const actualSum = value['actual'].reduce((acc, {value}) => acc + value, 0);
        const forecastedSum = value['forecasted'].reduce((acc, {value}) => acc + value, 0);
        const savingsSum = value['savings'].reduce((acc, {value}) => acc + value, 0);
        sheetData.push({
            'Month': 'Total',
            'Actual': actualSum,
            'Forecasted': forecastedSum,
            'Savings': savingsSum
        });
        sheets[key] = XLSX.utils.json_to_sheet(sheetData)
        sheetNames.push(key)
    }



    const wb = { Sheets: sheets, SheetNames: sheetNames}
    const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'})
    const fileData = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'})
    FileSaver.saveAs(fileData, filename + '.xlsx')
}