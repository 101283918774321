import React from 'react';
import './styles.css';

const Dropdown = ({ label, handleChange, options }) => {
  const renderDropdownOptions = () => {
    return options.map((option, index) => {
      return (
        <option className='dropdownOption' key={index}>
          {option}
        </option>
      );
    });
  };
  return (
    <div className='dropdownWrap'>
      <select className='formDropdown' data-testid='dropdown' onChange={handleChange}>
        <option disabled selected={true}>
          --- Please Select {label} ---
        </option>
        {renderDropdownOptions()}
      </select>
    </div>
  );
};

export default Dropdown;
