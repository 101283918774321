import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink } from '@apollo/client';

import { Provider } from 'react-redux';
import store from '$store';

import { ThemeProvider, createTheme } from '@mui/material/styles';

import './styles.css';

const theme = createTheme({
  palette: {
    primary: {
      main: '#304659',
      light: '#E1E9F0',
    },
    success: {
      main: '#5CCC8A',
    },
    warning: {
      main: '#FEB900',
    },
    error: {
      main: '#E25674',
    },
    text: {
      primary: '#304659',
      secondary: '#8298AB',
    },
  },
});

const link = new HttpLink({
  uri: 'http://localhost:8080/graphql/',
});

const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache({
    addTypename: false,
  }),
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <ApolloProvider client={client}>
          <App />
        </ApolloProvider>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
