import buildingTypes from './building.types';
import { getPoints, getUtilityData } from 'api';
import moment from 'moment';

export const getAllBuildingsSuccess = (clients) => ({
  type: buildingTypes.GET_ALL_BUILDINGS_SUCCESS,
  payload: clients,
});

export const getAllBuildingsFailure = (error) => ({
  type: buildingTypes.GET_ALL_BUILDINGS_FAILURE,
  payload: error,
});

export const loadBuilding = (id) => async (dispatch) => {
  const data = {};

  // CONTACTS - MOCK
  data.contacts = {
    name: '1 Main St',
    openIssues: 'NA',
    inProgressIssues: 'NA',
    lifetimeSavings: 'NA',
    annualSavings: 'NA',
    firstContactTitle: 'Operator',
    firstContactName: 'John Smith',
    firstContactInfo: 'john@example.com',
    secondContactTitle: 'HVAC Contractor',
    secondContactName: 'Simple HVAC',
    secondContactInfo: '123-456-7890',
  };
  const points = [
    {id: 1003153, point: 'B1-S', currentValue: true},
    {id: 1003174, point: 'B2-S', currentValue: true},
    {id: 1003227, point: 'BLDG P1 ALARM', currentValue: true},
    {id: 1003209, point: 'BLDG P2 ALARM', currentValue: true},
    {id: 1003197, point: 'BLD-SWT', currentValue: true},
    {id: 1003197, point: 'BLD-SWT-Series', currentValue: false},
    {id: 1003170, point: 'BLR1-ALM', currentValue: true},
    {id: 1003159, point: 'BLR2-ALM', currentValue: true},
    {id: 1003163, point: 'DHW-SWT', currentValue: true},
    {id: 1003163, point: 'DHW-SWT-Series', currentValue: false},
    {id: 1003212, point: 'HBLR1-ALM', currentValue: true},
    {id: 1003205, point: 'HBLR2-ALM', currentValue: true},
    {id: 1003224, point: 'HBLR3-ALM', currentValue: true},
    {id: 1003198, point: 'HTG_BLR1-S', currentValue: true},
    {id: 1003215, point: 'HTG_BLR2-S', currentValue: true},
    {id: 1003192, point: 'HTG_BLR3-S', currentValue: true},
    {id: 1003143, point: 'MUA_FAN-S', currentValue: true},
    {id: 1003219, point: 'SUMWIN-S', currentValue: true},
    {id: 1003136, point: 'MUA_DA-TEMP', currentValue: true}
  ];
  const pointValues = await Promise.all(points.map(({id, point, currentValue}) => getPoints(id, point, currentValue)));
  const pointMap = {}
  pointValues.forEach(({point, value}) => pointMap[point] = value);
  data.points = pointMap;

  // savings
  const gasValues = [
    ['11',	36743,],
    ['12',	49729,],
    ['1',	55300,],
    ['2',	67979,],
    ['3',	50482,],
    ['4',	40039,],
    ['5',	31771,],
    ['6',	16041,],
    ['7',	13774,],
    ['8',	13302,],
    ['9',	12422,],
    ['10',	24210,],
  ];
  const oldGasValues = [
    ['11', 0],
    ['12', 0],
    ['1',	113541,],
    ['2',	61067,],
    ['3',	49141,],
    ['4',	45034,],
    ['5',	38507,],
    ['6',	16353,],
    ['7',	13381,],
    ['8',	12613,],
    ['9',	17770,],
    ['10',	26855,],
  ]
  const waterValues = [
    ['10',	4799.58],
    ['11',	3356.53],
    ['12',	4776.49],
    ['01',	4653.06],
    ['02',	4202.47],
    ['04',	4875.58],
    ['05',	4619.78],
    ['05',	4680.18],
    ['07',	5591.99],
    ['08',	5497.77],
    ['09',	5439.24],
  ];
  const oldWaterValues = [
    ['10', 0],
    ['11', 0],
    ['12',	4433.4],
    ['01',	4480.69],
    ['02',	4487.67],
    ['03',	4825],
    ['04',	4849.36],
    ['05',	4880.68],
    ['06',	5433.88],
    ['07',	5468.08],
    ['08',	5513.71],
    ['09',	3431.47],
  ]

  const elecValues = [
    ['10',	113941.155],
    ['11',	114347.169],
    ['12',	116729.237],
    ['01',	123789.887],
    ['02',	124022.853],
    ['03',	104787.861],
    ['04',	111960.562],
    ['05',	112664.616],
    ['06',	132350.406],
    ['07',	141452.03],
    ['08',	153784.194],
    ['09',	135108.313],
  ];
  const oldElecValues = [
    ['10', 0],
    ['11', 0],
    ['12', 0],
    ['01',	119220.424],
    ['02',	118005.747],
    ['03',	108621.17],
    ['04',	120416.072],
    ['05',	113621.341],
    ['06',	144365.597],
    ['07',	181240.588],
    ['08',	171878.758],
    ['09',	131595.144],
  ]
  data.gasValues = gasValues;
  data.oldGasValues = oldGasValues;
  data.elecValues = elecValues;
  data.oldElecValues = oldElecValues;
  data.waterValues = waterValues;
  data.oldWaterValues = oldWaterValues;

  // health
  const formatNum = (value) => Math.round(parseFloat(value) * 10) / 10;
  data.health = [
    {label: 'Heating Plant', items: [
      {label: 'Boiler 1', type: 'alert', alert: pointMap['HBLR1-ALM'].value !== 'Normal'},
      {label: 'Boiler 2', type: 'alert', alert: true},
      {label: 'Boiler 3', type: 'alert', alert: pointMap['HBLR3-ALM'].value !== 'Normal'},
      {label: 'Pump 1', type: 'alert', alert: pointMap['BLDG P1 ALARM'].value !== 'NORMAL'},
      {label: 'Pump 2', type: 'alert', alert: pointMap['BLDG P2 ALARM'].value !== 'NORMAL'},
      {label: 'Heating Supply Temp', type: 'value', value: formatNum(pointMap['BLD-SWT'].value)}

    ]},
    {label: 'Domestic Hot Water', items: [
      {label: 'DHW BLR 1', type: 'alert', alert: pointMap['BLR1-ALM'].value !== 'Normal'},
      {label: 'DHW BLR 2', type: 'alert', alert: pointMap['BLR2-ALM'].value !== 'Normal'},
      {label: 'DHW Supply Temp', type: 'value', value: formatNum(pointMap['DHW-SWT'].value)}
    ]},
    {label: 'Ventilation', items: [
      {label: 'Make-up Air Unit', type: 'alert', alert: pointMap['MUA_FAN-S'].value !== 'On'},
      {label: 'Air Supply Temp', type: 'value', value: formatNum(pointMap['MUA_DA-TEMP'].value)}
    ]}
  ]
  data.health = data.health.map(category => ({
    ...category,
    alert: category.items.some(item => item.alert)
  }));
  data.alertCount = 0;
  data.health.forEach(category => category.items.forEach(item => item.alert && (data.alertCount = data.alertCount + 1)))

  // heating
  const convertTimestamp = ({year, monthValue, dayOfMonth, hour, minute}) => (
    moment({year, month: parseInt(monthValue) - 1, day: dayOfMonth, hour, minute}).format('HH:mm')
  );
  data.coolingMachineValues = pointMap['DHW-SWT-Series'].map(point => ({...point, time: convertTimestamp(point.timestamp)}));
  data.coolingMachineValues.reverse();
  data.heatingMachineValues = pointMap['BLD-SWT-Series'].map(point => ({...point, time: convertTimestamp(point.timestamp)}));;
  data.heatingMachineValues.reverse();
  data.heatingValue = Math.round(parseFloat(pointMap['BLD-SWT'].value));
  data.coolingValue = Math.round(parseFloat(pointMap['DHW-SWT'].value));
  data.coolingMachineStatuses = [
    {label: 'DHW Boiler 1', status: pointMap['B1-S'].value === 'ON'},
    {label: 'DHW Boiler 2', status: pointMap['B2-S'].value === 'ON'},
  ];
  data.heatingMachineStatuses = [
    {label: 'Heating Boiler 1', status: pointMap['HTG_BLR1-S'].value === 'ON'},
    {label: 'Heating Boiler 2', status: pointMap['HTG_BLR2-S'].value === 'ON'},
    {label: 'Heating Boiler 3', status: pointMap['HTG_BLR3-S'].value === 'ON'},
  ]

  

  // new utility
  data.utility = await getUtilityData();
  // savings
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0
  });
  data.totalSavingsYTD = data.utility.totalSavings;
  data.totalSavingsYTDF = formatter.format(data.totalSavingsYTD);
  data.totalSavingsMonthly = data.totalSavingsYTD / 12;
  data.totalSavingsMonthlyF = formatter.format(data.totalSavingsMonthly);
  data.totalSavingsWeekly = data.totalSavingsMonthly / 4;
  data.totalSavingsWeeklyF = formatter.format(data.totalSavingsWeekly);
  data.totalSavingsDaily = data.totalSavingsMonthly / 30;
  data.totalSavingsDailyF = formatter.format(data.totalSavingsDaily);
  dispatch({type: buildingTypes.BUILDING_LOADING_SUCCESS, payload: data});
};