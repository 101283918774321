import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { loadBuilding } from '../../store/Building/building.actions';
import { Typography } from '@mui/material';
import SmartSiteScaffold from 'components/SmartSiteScaffold';
import Info from '../../components/BuildingComponents/Info';
import HealthInvestments from '../../components/BuildingComponents/HealthInvestments';
import Statistics from '../../components/BuildingComponents/Statistics';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
  },
  title: {
    marginBottom: '16px !important',
  },
  info: {
    flexGrow: 3,
  },
  healthInvestments: {
    flexGrow: 4,
    marginLeft: 32
  },
  statistics: {
    flexGrow: 4,
    maxWidth: '50vw',
    marginLeft: 32
  },
}));

const BuildingViewPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentBuilding = useSelector(store => store.building.currentBuilding);
  const loading = useSelector(store => store.building.loading);
  useEffect(() => {
    const id = setInterval(() => dispatch(loadBuilding(0)), 300000)
    if (currentBuilding === null) dispatch(loadBuilding(0));
    return () => clearInterval(id);
  }, []);

  return (
    loading || currentBuilding === null ? null : (
      <SmartSiteScaffold 
        defaultHidden={true} 
        defaultSelection={0}
        totalSavings={currentBuilding.totalSavingsYTDF}
        totalSavingsMonthly={currentBuilding.totalSavingsMonthlyF}
        totalSavingsWeekly={currentBuilding.totalSavingsWeeklyF}
        totalSavingsDaily={currentBuilding.totalSavingsDailyF}
      >
        <Typography variant='h3' className={classes.title}>
          {currentBuilding.contacts.name}
        </Typography>
        <div className={classes.wrapper}>
          <div className={classes.info}>
            <Info  building={currentBuilding}/>
          </div>
          <div className={classes.healthInvestments}>
            <HealthInvestments health={currentBuilding.health} investments={currentBuilding.investments} />
          </div>
          <div className={classes.statistics}>
            <Statistics
              utilValues={currentBuilding.utility}
              heatingMachineValues={currentBuilding.heatingMachineValues}
              coolingMachineValues={currentBuilding.coolingMachineValues}
              heatingMachineStatuses={currentBuilding.heatingMachineStatuses}
              coolingMachineStatuses={currentBuilding.coolingMachineStatuses}
              heatingValue={currentBuilding.heatingValue}
              coolingValue={currentBuilding.coolingValue}
            />
          </div>
        </div>
      </SmartSiteScaffold>
    )
  );
};

export default BuildingViewPage;
