import { gql } from '@apollo/client';

export const API_KEY_TYPES = ['REPORTKEY', 'SMARTSITEKEY'];

export const ADDRESS_TYPES = ['MAILING', 'BILLING'];

export const STATES_PROVINCES = [
  'Alberta',
  'British Columbia',
  'Manitoba',
  'New Brunswick',
  'Newfoundland & Labrador',
  'North West Territories',
  'Nova Scotia',
  'Nunavut',
  'Ontario',
  'Prince Edward Island',
  'Quebec',
  'Saskatchewan',
  'Yukon',
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Conneticut',
  'District of Columbia',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

// graphql queries

export const GET_CLIENT_BY_ID = gql`
  query($id: ID) {
    getClientById(id: $id) {
      id
      name
      keyStores {
        key
        type
      }
      buildings {
        id
        name
        client {
          id
        }
      }
    }
  }
`;

export const GET_BUILDING_BY_ID = gql`
  query($buildingId: ID) {
    getBuildingById(buildingId: $buildingId) {
      id
      name
      client {
        id
      }
      address {
        streetAddress
        city
        state
        code
        country
      }
    }
  }
`;

export const GET_ALL_CLIENTS = gql`
  query {
    getAllClients {
      id
      name
      keyStores {
        key
        type
      }
      buildings {
        id
        name
        client {
          id
        }
      }
    }
  }
`;

export const GET_ALL_BUILDINGS = gql`
  query {
    getAllBuildings {
      id
      name
      client {
        id
      }
    }
  }
`;

export const GET_ALL_KEYSTORES = gql`
  query {
    getAllKeyStores {
      id
      key
      type
    }
  }
`;

// graphql mutations
export const CREATE_ADDRESS = gql`
  mutation($streetAddress: String, $code: String, $city: String, $state: String, $country: String, $type: AddressType) {
    createAddress(
      streetAddress: $streetAddress
      code: $code
      city: $city
      state: $state
      country: $country
      type: $type
    ) {
      id
    }
  }
`;

export const CREATE_BUILDING = gql`
  mutation($name: String) {
    createBuilding(name: $name) {
      id
    }
  }
`;

export const CREATE_CLIENT = gql`
  mutation($name: String!) {
    createClient(name: $name) {
      id
    }
  }
`;

export const CREATE_KEY_STORE_FOR_CLIENT = gql`
  mutation($clientId: ID, $key: String, $type: KeyType) {
    createKeyStoreForClient(clientId: $clientId, key: $key, type: $type) {
      id
      name
      keyStores {
        id
        key
        type
      }
    }
  }
`;

export const ADD_ADDRESS_TO_BUILDING = gql`
  mutation($buildingId: ID, $addressId: ID) {
    createAddressForBuilding(buildingId: $buildingId, addressId: $addressId) {
      id
      name
      address {
        streetAddress
      }
    }
  }
`;

export const CREATE_BUILDING_WITH_ADDRESS = gql`
  mutation($name: String, $streetAddress: String, $code: String, $city: String, $state: String, $country: String) {
    createBuildingWithAddress(
      name: $name
      streetAddress: $streetAddress
      code: $code
      city: $city
      state: $state
      country: $country
    ) {
      id
    }
  }
`;

export const CREATE_BUILDING_FOR_CLIENT = gql`
  mutation(
    $clientId: ID
    $buildingName: String
    $streetAddress: String
    $code: String
    $city: String
    $state: String
    $country: String
  ) {
    createBuildingForClient(
      clientId: $clientId
      buildingName: $buildingName
      streetAddress: $streetAddress
      code: $code
      city: $city
      state: $state
      country: $country
    ) {
      id
    }
  }
`;

export const ADD_BUILDING_TO_CLIENT = gql`
  mutation($clientId: ID, $buildingId: ID) {
    addBuildingToClient(clientId: $clientId, buildingId: $buildingId) {
      id
    }
  }
`;
