import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllClientsFailure, getAllClientsSuccess } from '../../store/Client/client.actions';
import { useQuery } from '@apollo/client';
import Table from 'components/Table';
import * as Constants from '../../common/constants';

const headers = ['ID', 'Name', 'API Keys', 'Buildings'];

const ClientTable = () => {
  const clients = useSelector((state) => state.client.clients);
  const dispatch = useDispatch();
  const { loading, error, data } = useQuery(Constants.GET_ALL_CLIENTS, {
    pollInterval: 2000,
  }); // this query fires every 2s for table update - not practical

  if (loading) return 'Loading...';
  if (error) dispatch(getAllClientsFailure(error.message));
  else {
    dispatch(getAllClientsSuccess(data.getAllClients));
  }

  return (
    <div className='tableWrap' data-testid='clientTable'>
      <Table className='clientTable' label='CURRENT CLIENTS' headers={headers} data={clients}></Table>
    </div>
  );
};

export default ClientTable;
