import React from 'react';
import HeatingCoolingInfo from './HeatingCoolingInfo';
import GasWaterElecInfo from './GasWaterElecInfo';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({}));

const Statistics = ({ heatingMachineStatuses, coolingMachineStatuses, heatingValue, coolingValue, utilValues, heatingMachineValues, coolingMachineValues }) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <GasWaterElecInfo utilValues={utilValues} />
      <HeatingCoolingInfo heatingValue={heatingValue} coolingValue={coolingValue} heatingMachineStatuses={heatingMachineStatuses} coolingMachineStatuses={coolingMachineStatuses} heatingMachineValues={heatingMachineValues} coolingMachineValues={coolingMachineValues} />
    </div>
  );
};

export default Statistics;
