import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import AdminHomePage from 'pages/AdminHomePage';
import LoginPage from 'pages/LoginPage';
import HomePage from 'pages/HomePage';
import BuildingViewPage from 'pages/BuildingViewPage';

function App() {
  return (
    <Router>
      <Switch>
        <Route path='/home'>
          <HomePage />
        </Route>
        <Route path='/building/:id'>
          <BuildingViewPage />
        </Route>
        <Route path='/admin'>
          <AdminHomePage />
        </Route>
        <Route path='/'>
          <LoginPage />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
