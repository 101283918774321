import BuildingTable from '../../BuildingTable';
import ClientTable from '../../ClientTable';
import React from 'react';
import CreateClient from '../../CreateClient';
import './styles.css';

const AdminDashboard = () => {
  return (
    <div className='viewWrap' data-testid='adminDashboardWrap'>
      <div className='viewHeader' data-testid='adminDashboardHeader'>
        <h3>ADMIN DASHBOARD</h3>
      </div>
      <div className='createWrap' data-testid='createWrap'>
        <CreateClient className='createClient' data-testid='createClient' />
      </div>
      <div className='tableWrap' data-testid='adminDashboardTableWrap'>
        <ClientTable className='clientTable' data-testid='clientTable' />
        <BuildingTable className='buildingTable' data-testid='buildingTable' />
      </div>
    </div>
  );
};

export default AdminDashboard;
