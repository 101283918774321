import React, { useState } from 'react';
import FormInput from '../Forms/FormInput';
import Button from '../Forms/Button';
import { useMutation } from '@apollo/client';
import * as Constants from '../../common/constants';

const CreateClient = () => {
  const [clientName, setClientName] = useState('');
  const [createClient, { loading, error }] = useMutation(Constants.CREATE_CLIENT);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    createClient({ variables: { name: clientName } });
    setClientName('');
  };

  return (
    <div className='formWrap'>
      <form className='form' onSubmit={handleFormSubmit}>
        <FormInput
          data-testid='createClientFormInput'
          className='formInput'
          type='text'
          name='fullName'
          value={clientName}
          placeholder='Full Name'
          handleChange={(e) => setClientName(e.target.value)}
        />
        <Button data-testid='createClientButton' className='formButton' type='submit'>
          Create New Client
        </Button>
      </form>
      {loading && <p>Loading...</p>}
      {error && <p>Error: please try again</p>}
    </div>
  );
};

export default CreateClient;
