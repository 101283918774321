import React from 'react';
import { Card, CardContent, Typography, Paper, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Icon from '../Icons';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {useHistory} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(1),
    maxWidth: '300px',
    cursor: 'pointer'
  },
  name: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    display: 'flex',
    fontSize: '1.3rem !important',
    justifyContent: 'space-between'
  },
  menuIcon: {
    color: 'white',
  },
  content: {
    padding: 0,
    paddingBottom: '0 !important',
  },
  grid: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
  gridItem: {
    width: '45%',
    marginBottom: theme.spacing(2),
    height: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  largeGridItem: {
    width: '100%',
    marginBottom: theme.spacing(2),
    height: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyCOntent: 'space-around',
    alignItems: 'center'
  },
  icon: {
    fontSize: '32px !important',
  },
  lastUpdated: {
    textAlign: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.primary.light,
  },
  successIcon: {
    color: theme.palette.success.main,
  },
  warningIcon: {
    color: theme.palette.warning.main,
  },
  errorIcon: {
    color: theme.palette.error.main,
  },
}));

const BuildingCard = ({ name, status, health, savings, lastUpdated, alerts, disabled }) => {
  const classes = useStyles();
  const statusIcons = {
    ac: <Icon name='Cool' />,
    heat: <Icon name='Heat' />,
    fan: <Icon name='Fan' />,
  };
  const statusIcon = statusIcons[status];
  const healthIcons = {
    ok: <CheckCircleRoundedIcon className={`${classes.icon} ${classes.successIcon}`} />,
    warn: <CancelRoundedIcon className={`${classes.icon} ${classes.errorIcon}`} />,
    err: <ErrorRoundedIcon className={`${classes.icon} ${classes.errorIcon}`} />,
  };
  const healthIcon = healthIcons[health];
  const history = useHistory();
  return (
    <Card className={classes.card} onClick={() => disabled || history.push('/building/1')}>
      <CardContent className={classes.content}>
        <Typography variant='h5' className={classes.name}>
          {name}
          <IconButton aria-label="settings">
            <ChevronRightIcon className={classes.menuIcon} />
          </IconButton>
        </Typography>
        <div className={classes.grid}>
          <Paper className={classes.gridItem}>
            <Typography variant='overline' className={classes.itemTitle}>
              status
            </Typography>
            {statusIcon}
            <Typography variant='subtitle1' className={classes.space} />
          </Paper>
          <Paper className={classes.gridItem}>
            <Typography variant='overline' className={classes.itemTitle}>
              health
            </Typography>
            {healthIcon}
            <Typography variant='subtitle1' className={classes.space} />
          </Paper>
          {/*<Paper className={classes.gridItem}>
            <Typography variant='overline' className={classes.itemTitle}>
              investments
            </Typography>
            <Typography variant='h4' className={classes.invCount}>
              {investments.value}
            </Typography>
            <Typography variant='subtitle2' className={classes.invLabel}>
              {investments.label}
            </Typography>
  </Paper>*/}
          <Paper className={classes.gridItem}>
            <Typography variant='overline' className={classes.itemTitle}>
              alerts
            </Typography>
            <Typography variant='h4' className={classes.savings}>
              {alerts}
            </Typography>
            <Typography variant='subtitle1' className={classes.space} />
          </Paper>
          <Paper className={classes.gridItem}>
            <Typography variant='overline' className={classes.itemTitle}>
              savings ytd
            </Typography>
            <Typography variant='h4' className={classes.savings}>
              {savings}
            </Typography>
            <Typography variant='subtitle1' className={classes.space} />
          </Paper>
        </div>
        <Typography variant='subtitle2' className={classes.lastUpdated}>
          Last updated {lastUpdated}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default BuildingCard;
