import React, { useState } from 'react';
import FormInput from '../Forms/FormInput';
import Button from '../Forms/Button';
import { useMutation } from '@apollo/client';
import Dropdown from 'components/Forms/Dropdown';
import * as Constants from '../../common/constants';

const CreateKeyStore = ({ clientId }) => {
  const [key, setKey] = useState('');
  const [type, setType] = useState('');
  const [createKeyStoreForClient, { loading, error }] = useMutation(Constants.CREATE_KEY_STORE_FOR_CLIENT);

  const handleFormSubmit = (e) => {
    createKeyStoreForClient({ variables: { clientId: clientId, key: key, type: type } });
    setKey('');
    setType('');
  };

  return (
    <div className='formWrap'>
      <form className='form' data-testid='createKeyStoreForm' onSubmit={handleFormSubmit}>
        <FormInput
          className='formInput'
          data-testid='createKeyStoreInput'
          value={key}
          placeholder='API Key'
          handleChange={(e) => setKey(e.target.value)}
        />
        <Dropdown
          className='formDropdown'
          label='API Key Type'
          options={Constants.API_KEY_TYPES}
          handleChange={(e) => setType(e.target.value)}
        />
        <Button className='formButton' type='submit' data-testid='createKeyStoreButton'>
          Add Key To Client
        </Button>
      </form>
      {loading && <p>Loading...</p>}
      {error && <p>Error: please try again</p>}
    </div>
  );
};

export default CreateKeyStore;
