import React from 'react';
import {useHistory} from 'react-router-dom';
import { Tabs, Tab } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  tab: {
    color: 'white !important'
  }
}));

const Menu = ({ defaultSelection }) => {
  const history = useHistory();
  const classes = useStyles();
  return (
    <Tabs value={defaultSelection}>
      <Tab className={classes.tab} label='Portfolio' onClick={() => history.push('/home')}/>
    </Tabs>
  );
};

export default Menu;
