import React, { useState } from 'react';
import FormInput from 'components/Forms/FormInput';
import ClientCard from 'cards/ClientCard';
import Button from 'components/Forms/Button';
import { useLazyQuery } from '@apollo/client';
import * as Constants from 'common/constants';

const ClientSearch = () => {
  const [clientId, setClientId] = useState('');
  const [getClientById, { loading, data, error }] = useLazyQuery(Constants.GET_CLIENT_BY_ID, {
    fetchPolicy: 'network-only',
  });

  return (
    <div className='searchWrap' data-testid='clientSearchWrap'>
      <div className='searchForm' data-testid='clientSearchForm'>
        <FormInput
          className='formInput'
          data-testid='clientSearchFormInput'
          name='clientId'
          value={clientId}
          placeholder='Client Id'
          onChange={(e) => setClientId(e.target.value)}
        />
        <Button
          onClick={() => getClientById({ variables: { id: clientId } })}
          className='formButton'
          data-testid='clientSearchFormButton'
        >
          Search
        </Button>
      </div>
      <div className='searchResult' data-testid='clientSearchResult'>
        {loading && (
          <p className='searchLoading' data-testid='clientSearchLoading'>
            Loading...
          </p>
        )}
        {error && (
          <p className='searchError' data-testid='clientSearchError'>
            Error!
          </p>
        )}
        {data && data.getClientById && (
          <ClientCard className='card' data-testid='clientCard' client={data.getClientById} />
        )}
      </div>
    </div>
  );
};

export default ClientSearch;
