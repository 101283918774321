import React from 'react';
import {makeStyles} from '@mui/styles';
import clsx from 'clsx';

const icons = {
    'AirCirculation': require('./Icon_AirCirculation_Navy.png'),
    'AirSample': require('./Icon_AirSample_Navy.png'),
    'Alert': require('./Icon_Alert_Navy.png'),
    'Bluetooth': require('./Icon_Bluetooth_Navy.png'),
    'Certified': require('./Icon_Certified_Navy.png'),
    'CloudSpace': require('./Icon_CloudSpace_Navy.png'),
    'Cool': require('./Icon_Cool_Navy.png'),
    'DetectChamber': require('./Icon_DetectChamber_Navy.png'),
    'Fan': require('./Icon_Fan_Navy.png'),
    'Heat': require('./Icon_Heat_Navy.png'),
    'Laser': require('./Icon_Laser_Navy.png'),
    'Notification': require('./Icon_Notification_Navy.png'),
    'Power': require('./Icon_Power_Navy.png'),
    'Search': require('./Icon_Search_Navy.png'),
    'Temp': require('./Icon_Temp_Navy.png'),
    'Vacant': require('./Icon_Vacant_Navy.png'),
    'VirusFree': require('./Icon_VirusFree_Navy.png'),
    'Wifi': require('./Icon_Wifi_Navy.png'),
};

const useStyles = makeStyles((theme) => ({
    icon: {
        width: 32,
        height: 32
    }
}));

const Icon = ({name, className}) => {
    const classes = useStyles();
    const iconSrc = icons[name];
    return <img alt='icon' src={iconSrc} className={clsx(classes.icon, className)} />;
}

export default Icon;
