import buildingTypes from './building.types';

const INIT_STATE = {
  loading: false,
  buildings: [],
  error: '',
  currentBuilding: null,
};

const buildingReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case buildingTypes.GET_ALL_BUILDINGS_SUCCESS:
      return {
        ...state,
        buildings: action.payload,
        error: '',
      };
    case buildingTypes.GET_ALL_BUILDINGS_FAILURE:
      return {
        ...state,
        buildings: [],
        error: action.payload,
      };
    case buildingTypes.BUILDING_LOADING:
      return {
        ...state,
        loading: true,
      };
    case buildingTypes.BUILDING_LOADING_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case buildingTypes.BUILDING_LOADING_SUCCESS:
      return {
        ...state,
        loading: false,
        currentBuilding: action.payload,
      };
    default:
      return state;
  }
};

export default buildingReducer;
