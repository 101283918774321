import React from 'react';
import './styles.css';

const renderTableCells = (item) => {
  return Object.entries(item).map((entry, index) => {
    let [, value] = entry;
    if (typeof value === 'object' && value.length !== undefined) {
      // multiple objects so return count
      value = value.length;
    } else if (typeof value === 'object' && value.id !== undefined) {
      // one object so return id
      value = value.id;
    }
    return (
      <td className='tableCell' key={index}>
        {value}
      </td>
    );
  });
};

const Table = (props) => {
  const renderTableHeaders = () => {
    return props.headers.map((key, index) => {
      return (
        <th className='tableHeader' key={index}>
          {key.toUpperCase()}
        </th>
      );
    });
  };
  const renderTableData = () => {
    if (props.data.length > 0) {
      return props.data.map((item, index) => {
        return (
          <tr className='tableRow' key={index}>
            {renderTableCells(item)}
          </tr>
        );
      });
    } else {
      return (
        <tr className='tableRow' key={0}>
          {props.headers.map((item, index) => {
            return <td className='tableCell' key={index}></td>;
          })}
        </tr>
      );
    }
  };
  return (
    <div className='tableWrap'>
      {props.data && (
        <table className='table'>
          <caption className='tableLabel'>{props.label}</caption>
          <tr className='tableRow'>{renderTableHeaders()}</tr>
          <tbody className='tableBody'>{renderTableData()}</tbody>
        </table>
      )}
    </div>
  );
};

export default Table;
