import { combineReducers } from 'redux';
import buildingReducer from './Building/building.reducer';
import clientReducer from './Client/client.reducers';
import keyStoreReducer from './KeyStore/keyStore.reducers';

export default combineReducers({
  client: clientReducer,
  keyStore: keyStoreReducer,
  building: buildingReducer,
});
