import React, { useState } from 'react';
import FormInput from '../Forms/FormInput';
import Button from '../Forms/Button';
import { useMutation } from '@apollo/client';
import Dropdown from 'components/Forms/Dropdown';

import * as Constants from 'common/constants';

const CreateBuilding = ({ clientId }) => {
  const [buildingName, setBuildingName] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [code, setCode] = useState('');
  const [country, setCountry] = useState('');
  const [createBuildingForClient, { loading, error }] = useMutation(Constants.CREATE_BUILDING_FOR_CLIENT);
  const handleFormSubmit = (e) => {
    createBuildingForClient({
      variables: {
        clientId: clientId,
        buildingName: buildingName,
        streetAddress: streetAddress,
        code: code,
        city: city,
        state: state,
        country: country,
      },
    });
  };
  return (
    <div className='formWrap'>
      <form className='form' data-testid='createBuildingForm' onSubmit={handleFormSubmit}>
        <FormInput
          className='formInput'
          name='buildingName'
          value={buildingName}
          placeholder='Building Name'
          handleChange={(e) => setBuildingName(e.target.value)}
        />
        <FormInput
          className='formInput'
          name='streetAddress'
          value={streetAddress}
          placeholder='Street Address'
          handleChange={(e) => setStreetAddress(e.target.value)}
        />
        <FormInput
          className='formInput'
          name='city'
          value={city}
          placeholder='City'
          handleChange={(e) => setCity(e.target.value)}
        />
        <Dropdown
          label='Province/State'
          className='formDropdown'
          options={Constants.STATES_PROVINCES}
          handleChange={(e) => setState(e.target.value)}
        ></Dropdown>
        <FormInput
          className='formInput'
          name='code'
          value={code}
          placeholder='Postal/Zip Code'
          handleChange={(e) => setCode(e.target.value)}
        />
        <FormInput
          className='formInput'
          name='country'
          value={country}
          placeholder='Country'
          handleChange={(e) => setCountry(e.target.value)}
        />
        <Button className='formButton' type='submit' data-testid='createBuildingButton'>
          Create Building
        </Button>
      </form>
      {loading && <p>Loading...</p>}
      {error && <p>Error: please try again</p>}
    </div>
  );
};

export default CreateBuilding;
